var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"text-pink\"> <span class=\"fas fa-lock\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"activerecord.attributes.itinerary.pink",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":98},"end":{"line":14,"column":152}}}))
    + " </span><br>";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<br> <span class=\"fas fa-redo\"></span> <span class=\"text-muted\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"activerecord.attributes.itinerary.daily",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":248},"end":{"line":14,"column":303}}}))
    + " </span> <span class=\"fas fa-clock\"></span> "
    + alias3((lookupProperty(helpers,"localize")||(depth0 && lookupProperty(depth0,"localize"))||alias2).call(alias1,"time.formats.time_only",(depth0 != null ? lookupProperty(depth0,"leave_date") : depth0),{"name":"localize","hash":{},"data":data,"loc":{"start":{"line":14,"column":347},"end":{"line":14,"column":395}}}))
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <span class=\"text-muted\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"activerecord.attributes.itinerary.leave_date",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":430},"end":{"line":14,"column":490}}}))
    + ": </span>"
    + alias3((lookupProperty(helpers,"localize")||(depth0 && lookupProperty(depth0,"localize"))||alias2).call(alias1,"date.formats.long",(depth0 != null ? lookupProperty(depth0,"leave_date") : depth0),{"name":"localize","hash":{},"data":data,"loc":{"start":{"line":14,"column":499},"end":{"line":14,"column":542}}}))
    + " <span class=\"fas fa-clock\"></span> "
    + alias3((lookupProperty(helpers,"localize")||(depth0 && lookupProperty(depth0,"localize"))||alias2).call(alias1,"time.formats.time_only",(depth0 != null ? lookupProperty(depth0,"leave_date") : depth0),{"name":"localize","hash":{},"data":data,"loc":{"start":{"line":14,"column":578},"end":{"line":14,"column":626}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"round_trip") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":627},"end":{"line":14,"column":880}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<br><span class=\"text-muted\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"activerecord.attributes.itinerary.return_date",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":674},"end":{"line":14,"column":735}}}))
    + ": </span>"
    + alias3((lookupProperty(helpers,"localize")||(depth0 && lookupProperty(depth0,"localize"))||alias2).call(alias1,"date.formats.long",(depth0 != null ? lookupProperty(depth0,"return_date") : depth0),{"name":"localize","hash":{},"data":data,"loc":{"start":{"line":14,"column":744},"end":{"line":14,"column":788}}}))
    + " <span class=\"fas fa-clock\"></span> "
    + alias3((lookupProperty(helpers,"localize")||(depth0 && lookupProperty(depth0,"localize"))||alias2).call(alias1,"time.formats.time_only",(depth0 != null ? lookupProperty(depth0,"return_date") : depth0),{"name":"localize","hash":{},"data":data,"loc":{"start":{"line":14,"column":824},"end":{"line":14,"column":873}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-lg-6\">\n  <div class=\"card itinerary-thumbnail mb-2\" data-itinerary-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":64},"end":{"line":2,"column":70}}}) : helper)))
    + "\">\n    <div class=\"itinerary-thumbnail__stroke\" style=\"background-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"backgroundColor") || (depth0 != null ? lookupProperty(depth0,"backgroundColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"backgroundColor","hash":{},"data":data,"loc":{"start":{"line":3,"column":70},"end":{"line":3,"column":89}}}) : helper)))
    + "\"></div>\n    <div class=\"card-body\">\n      <div class=\"d-flex\">\n        <div class=\"flex-shrink-0\">\n          <img alt=\"\" class=\"me-2\" height=\"50\" src=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + "\" width=\"50\">\n        </div>\n        <div class=\"flex-grow-1\">\n          <h5 class=\"mb-0\"><a class=\"pre-line\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":10,"column":53},"end":{"line":10,"column":60}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":10,"column":62},"end":{"line":10,"column":71}}}) : helper)))
    + "</a></h5>\n          <p>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</p>\n        </div>\n      </div>\n      <p class=\"card-text\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":171}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"daily") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":14,"column":171},"end":{"line":14,"column":887}}})) != null ? stack1 : "")
    + "<br><span class=\"text-muted\">"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"activerecord.attributes.itinerary.fuel_cost",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":916},"end":{"line":14,"column":975}}}))
    + ": </span>"
    + alias4((lookupProperty(helpers,"currency")||(depth0 && lookupProperty(depth0,"currency"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"fuel_cost") : depth0),{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":14,"column":984},"end":{"line":14,"column":1006}}}))
    + " <br><span class=\"text-muted\">"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"activerecord.attributes.itinerary.tolls",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":1036},"end":{"line":14,"column":1091}}}))
    + ": </span>"
    + alias4((lookupProperty(helpers,"currency")||(depth0 && lookupProperty(depth0,"currency"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"tolls") : depth0),{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":14,"column":1100},"end":{"line":14,"column":1118}}}))
    + " </p>\n      <a class=\"card-link show-itinerary-on-map\" href=\"#\"> <span class=\"fas fa-map-marker-alt\"></span> "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"templates.itineraries.show_on_map",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":103},"end":{"line":15,"column":152}}}))
    + "</a>\n      <a class=\"card-link\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":40}}}) : helper)))
    + "\"> <span class=\"fas fa-info-circle\"></span> "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"templates.itineraries.read_more",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":131}}}))
    + "</a>\n    </div>\n  </div>\n</div>\n";
},"useData":true});