var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover popover--notifications\" role=\"tooltip\">\n  <div class=\"popover-arrow\"></div>\n  <h3 class=\"popover-header\"></h3>\n  <div class=\"popover-body-loading d-none\">\n    <div class=\"d-flex justify-content-center m-2\">\n      <div class=\"spinner-border text-primary\" role=\"status\">\n        <span class=\"visually-hidden\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"templates.loading",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":38},"end":{"line":7,"column":73}}}))
    + "</span>\n      </div>\n    </div>\n  </div>\n  <div class=\"popover-body\"></div>\n  <div class=\"popover-footer\">\n    <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"footer_url") || (depth0 != null ? lookupProperty(depth0,"footer_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"footer_url","hash":{},"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":13,"column":27}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"footer_link_text") || (depth0 != null ? lookupProperty(depth0,"footer_link_text") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"footer_link_text","hash":{},"data":data,"loc":{"start":{"line":13,"column":29},"end":{"line":13,"column":49}}}) : helper)))
    + "</a>\n  </div>\n</div>\n";
},"useData":true});