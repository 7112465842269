var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"notification-message\">\n    <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":4,"column":13},"end":{"line":4,"column":20}}}) : helper)))
    + "\">\n      <div class=\"d-flex\">\n        <div class=\"flex-shrink-0\">\n          <img class=\"me-2\" src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"sender") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + "\" width=\"50\" height=\"50\" alt=\"\">\n        </div>\n        <div class=\"flex-grow-1\">\n          <p class=\"mb-0 font-weight-bold text-truncate\">\n            "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"sender") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n          </p>\n          <p class=\"mb-0 text-muted text-truncate\">\n            "
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "\n          </p>\n          <p class=\"mb-0 text-muted\">\n            <small>"
    + alias3((lookupProperty(helpers,"localize")||(depth0 && lookupProperty(depth0,"localize"))||alias2).call(alias1,"datetime.formats.message",((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"date_sent") : stack1),{"name":"localize","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":76}}}))
    + "</small>\n          </p>\n        </div>\n      </div>\n  </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"notification-message-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});